<template lang="pug">
  .revenue-analysis
    .tip.m-b-30
      p {{ $t("revenue_analysis.you_can_see_report") }}
      p {{ $t("revenue_analysis.click_this_button") }}
    .d-flex.justify-content-center
      a(
        :href="revenueAnalysisPortalLink"
        target="_blank"
      )
        AppButton(title="revenue_analysis.see_report")

</template>

<script>
  // misc
  import { REVENUE_ANALYSIS_DATA_PORTAL_LINK } from "@/config/constants"

  export default {
    components: {
      AppButton: () => import("@/components/elements/AppButton")
    },

    data() {
      return {
        revenueAnalysisPortalLink: REVENUE_ANALYSIS_DATA_PORTAL_LINK
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .tip
    p
      font-size: 1.3em
      font-weight: 500

  ::v-deep
    .app-button
      background-color: $default-purple
      color: $default-white
      padding: 10px 35px
</style>
